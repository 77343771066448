import React, { useEffect, useState } from "react";
import { ReCaptcha } from "react-recaptcha-v3";
import { recaptcha_token } from "../../../config";
import { authActions } from "../../../context/actions/authActions";
import { ERROR_TYPES } from "../../../context/reducers/errorReducers";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import SaveBtn from "../../common/SaveBtn";
import Timer from "../../common/Timer/Timer";
import ForgotPassword from "./ForgotPassword";

var timerInterval = null;
var recaptcha;

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        clearInterval(timerInterval);
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);
    setCaptchaToken(false);
    updateToken();
  }, [forgotPassword]);

  function loginHandle(e) {
    e.preventDefault();
    if (process.env.NODE_ENV == "development") {
      verifyCallback("ok");
    } else {
      setIsLoading(true);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    if (!forgotPassword) {
      actions.loginUser({ login, password, captchaToken: recaptchaToken });
    }
    setCaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };

  const handleChangePassword = (e) => {
    dispatch({
      type: ERROR_TYPES.CLEAR_ERRORS,
    });

    setPassword(e.target.value);
  };

  return (
    <div className="d-center" style={{ minHeight: "100%" }}>
      <div className="d-center p-0 mt-4 mt-lg-0 mt-md-0 mx-auto auth-container flex-column">
        {!forgotPassword ? (
          <div
            className="d-flex align-items-center justify-content-center flex-column  py-5 animated fadeIn mx-auto"
            style={{ maxWidth: "300px", minWidth: "300px" }}
          >
            {/* <CanvasParticle id="canvas1" image64={logoPerceval} radius={50} /> */}
            <form
              onSubmit={loginHandle}
              className="d-flex align-items-center justify-content-center flex-column w-100 h-100 login-form"
            >
              <div className="mb-3 text-center">
                <img
                  className="img-fluid"
                  width="60"
                  src="/images/logo-small.svg"
                />
                {/* <CanvasParticle
                  id="canvas2"
                  image64={logoPercevalSmall}
                  radius={30}
                /> */}
                <div
                  className="mt-3 mb-3 text-success"
                  style={{ fontSize: 20, fontWeight: "700" }}
                >
                  Espace client
                </div>
              </div>
              <Control
                autoFocus={true}
                className="w-100"
                margin="my-2"
                label="Email"
                name="login"
                id="login"
                type="email"
                value={login}
                change={(e) => setLogin(e.target.value)}
                error={state.errors}
              />

              <Control
                className="w-100"
                margin="my-2"
                label="Mot de passe"
                name="password"
                id="password"
                type="password"
                value={password}
                change={handleChangePassword}
                error={state.errors}
              />
              <div className=" text-center d-flex flex-column justify-content-center align-items-center">
                {state.errors.nbAttempts > 0 && (
                  <span className="text-primary">
                    {state.errors.nbAttempts} tentative
                    {parseInt(state.errors.nbAttempts) > 1 ? "s" : ""} restante
                    {parseInt(state.errors.nbAttempts) > 1 ? "s" : ""}
                  </span>
                )}
                {!canLoggin && (
                  <>
                    <span className="text-danger mb-3">
                      L'accès à votre compte est temporairement bloqué suite à
                      plusieurs tentatives de connexion infructueuses.
                    </span>
                    <Timer timeLimit={timer} timerFinish={timerFinish} />
                  </>
                )}
              </div>
              {canLoggin && (
                <div className="text-center mt-5 mb-4 w-100">
                  <SaveBtn
                    className="animated fadeIn w-100 d-center"
                    isSaving={isLoading}
                    text="Connexion"
                    type="primary"
                    typeBtn="submit"
                  />
                </div>
              )}
              {canLoggin && (
                <div className="text-center w-100">
                  <button
                    type="button"
                    onClick={() => setForgotPassword(true)}
                    className="btn btn-outline-primary w-100"
                  >
                    Mot de passe oublié ?
                  </button>
                </div>
              )}
            </form>
          </div>
        ) : (
          <div className="">
            <ForgotPassword
              setForgotPassword={setForgotPassword}
              captchaToken={captchaToken}
            />
          </div>
        )}

        <div
          className="mb-5 w-100 d-flex flex-column align-items-end justify-content-end"
          style={{ maxWidth: 300 }}
        >
          <button
            className="btn btn-link pr-0"
            onClick={() => tools.openChat()}
          >
            Assistance par tchat
          </button>
          {/* <InstallAppButton /> */}
        </div>

        <div className=" text-center w-100" style={{ maxWidth: "300px" }}>
          This site is protected by reCAPTCHA and the Google{" "}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
          apply.
        </div>
      </div>
      {(isLoading || forgotPassword) && (
        <ReCaptcha
          ref={(ref) => (recaptcha = ref)}
          sitekey={recaptcha_token}
          action="login"
          verifyCallback={verifyCallback}
        />
      )}
    </div>
  );
};

export default Login;
