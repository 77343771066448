import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import ProducerButton from "../../../common/ProducerButton/ProducerButton";
import DownloadDocument from "./DownloadDocument";
import GreyCard from "../../Dashboard/GreyCard";

const ClientItem = ({ policy, idx, documents }) => {
  const [showDetail, setShowDetail] = useState(false);

  const producer = policy.producer2;

  return (
    <div
      className={`animated fadeInUp faster col-12 col-lg-6 px-0  ${
        idx % 2 == 0 ? "pl-lg-0 pr-lg-2" : "pr-lg-0 pl-lg-2"
      }`}
    >
      <GreyCard>
        <div
          onClick={() => setShowDetail(!showDetail)}
          className="w-100 cursor-pointer"
        >
          <div className="d-flex align-items-start flex-row justify-content-between w-100">
            <span
              className="text-success font-weight-bold"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {policy.client.company}
            </span>
            <i
              className={`fa fa-chevron-${
                showDetail ? "up" : "down"
              } text-primary`}
              style={{ fontSize: 18 }}
            />
          </div>

          <div className="d-flex align-items-start justify-content-start flex-column">
            <span>{policy.productType?.businessName}</span>
            <span>{policy.company?.name}</span>
            <strong>{policy.title}</strong>
            <strong>{policy.identification}</strong>
            {producer && (
              <div className="d-flex flex-row">
                <span className="mr-2" style={{ whiteSpace: "nowrap" }}>
                  Gestionnaire :{" "}
                </span>
                <ProducerButton producer={producer} />
              </div>
            )}
          </div>
        </div>

        <div
          className={`d-flex align-items-start justify-content-start flex-column  w-100 ${
            showDetail ? "mt-3" : ""
          }`}
          style={{
            overflow: "hidden",
            transition: "200ms",
            maxHeight: showDetail ? "500px" : "0px",
          }}
        >
          {policy.isGozen && (
            <strong className="d-flex align-items-end justify-content-start">
              Garanties {policy.isGozenPremium && "étendues"} activées
              <Link
                to="/protection-juridique?garantiesrenforcees"
                className="btn btn-link py-0 pl-2 pr-0"
                style={{
                  fontSize: 14,
                  textTransform: "lowercase",
                  textDecoration: "underline",
                }}
              >
                en savoir +
              </Link>
            </strong>
          )}
          {policy.options?.includes("OPTION_RC") && (
            <span>Recouvrement de créances</span>
          )}
          {policy.options?.includes("OPTION_FS") && (
            <span>Fiscal & social</span>
          )}
          {policy.isGozen ? (
            <span>
              Chiffre d'affaires :{" "}
              {tools.truenumberWithSpaces(policy.client.turnover)} €
            </span>
          ) : null}

          {policy.isGozen && (
            <div className="d-center my-4 w-100">
              {policy.isGozen && policy.lastAmendment?.canRenew && (
                <div className="d-flex justify-content-end">
                  <Link
                    className="btn btn-primary font-weight-bold btn-sm px-3 d-flex align-items-center justify-content-center"
                    to={"/contracts/" + policy.id + "/renew"}
                    style={{
                      height: 29,
                      fontSize: 14,
                    }}
                  >
                    <i className="fa fa-sync mr-2" />
                    Renouveler ce contrat
                  </Link>
                </div>
              )}
              {policy.isGozen && !policy.lastAmendment?.canRenew && (
                <div className="d-flex justify-content-end">
                  <Link
                    className="btn btn-primary font-weight-bold btn-sm px-3 d-flex align-items-center justify-content-center"
                    to={"/contracts/" + policy.id}
                    style={{
                      height: 29,
                      fontSize: 14,
                    }}
                  >
                    <i className="fa fa-edit mr-2" />
                    Modifier ce contrat
                  </Link>
                </div>
              )}
            </div>
          )}

          <strong>{policy.reference}</strong>
          <i>{policy.product?.businessName}</i>
          <span>
            Souscrit·e le{" "}
            {DateTime.fromISO(policy.effectDate).toFormat("dd/MM/yyyy")}
          </span>
          {policy.lastAmendment?.mainEcheance && (
            <span>
              Échéance principale le{" "}
              <strong>
                {DateTime.fromISO(policy.lastAmendment.mainEcheance).toFormat(
                  "dd/MM"
                )}
              </strong>
            </span>
          )}
          {policy.lastReceipt?.effectDate &&
            policy.lastReceipt.premiumWithVat && (
              <span>
                Du{" "}
                {DateTime.fromISO(policy.lastReceipt.effectDate).toFormat(
                  "dd/MM/yyyy"
                )}{" "}
                au{" "}
                {DateTime.fromISO(policy.lastReceipt.endDate).toFormat(
                  "dd/MM/yyyy"
                )}{" "}
                :{" "}
                <strong>
                  {policy.lastReceipt.premiumWithVat}
                  {" €"}
                </strong>
              </span>
            )}

          <div className="d-center  justify-content-around flex-wrap w-100 my-4">
            <DownloadDocument
              document={documents?.contrat}
              productName={policy.productType.name}
              companyName={policy.client.company}
              name="Contrat"
            />
            <DownloadDocument
              document={documents?.attestation}
              productName={policy.productType.name}
              companyName={policy.client.company}
              name="Attestation"
            />
            <DownloadDocument
              document={documents?.conditions}
              productName={policy.productType.name}
              companyName={policy.client.company}
              name="Conditions"
            />
          </div>
        </div>
      </GreyCard>
    </div>
  );
};

export default ClientItem;
