import React, { useState, useEffect } from "react";
import Accordion from "../../common/Accordion";
import Control from "../../common/Control";
import Tabs from "../../common/Tabs";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import useStore from "../../../context/useStore";
import AmendmentItem from "./AmendmentItem";
import { DateTime } from "luxon";
import DocumentsDisplay from "./DocumentsDisplay";
import { toast } from "react-toastify";
import AccordionItemTitle from "./AccordionItemTitle";
import { isMobile } from "react-device-detect";
import TimelineFiltered from "../../common/Timeline/TimelineFiltered";
import ReceiptItem from "./ReceiptItem";
import { Link } from "react-router-dom";

var keepDocumentK = [];

const TabPolicies = ({ policies, policySel, isBOA, clientId }) => {
  const [state, dispatch] = useStore();
  const [documents, setDocuments] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDoc, setIsLoadingDoc] = useState(false);
  const [amendments, setAmendments] = useState({});
  const [firstAmendment, setFirstAmendment] = useState(null);
  const [receipts, setReceipts] = useState({});

  var contractStatuses = state.constants.items.POLICIES_STATUS;

  useEffect(() => {
    if (policySel) {
      window.$('[data-ref="policy-' + policySel + '"]').collapse("show");
      getAmendments(policySel);
    }
  }, [policies]);

  useEffect(() => {
    if (amendments.length > 0) {
      setFirstAmendment(amendments[0][0]);
    }
  }, [amendments]);

  function getDocuments(policyId, shouldRefresh = false) {
    if (documents[policyId] && documents[policyId].length > 0 && !shouldRefresh) return false;
    setIsLoadingDoc(policyId);
    Axios.get(API_URL + "policies/" + policyId + "/files")
      .then((res) => {
        var docs = JSON.parse(JSON.stringify(documents));
        docs[policyId] = res.data;
        setDocuments(docs);
      })
      .then(() => {
        setIsLoadingDoc(false);
      });
  }

  function documentUploaded(polId, k, ck, doc) {
    var dcs = JSON.parse(JSON.stringify(documents));
    dcs[polId] = JSON.parse(JSON.stringify(keepDocumentK[polId]));
    dcs[polId][k][0] = JSON.parse(JSON.stringify(doc));
    toast.success("Document envoyé avec succès");
    setDocuments(dcs);
  }
  function documentRemoved(polId, k, ck, oldDoc) {
    var dcs = JSON.parse(JSON.stringify(documents));

    dcs[polId][k] = dcs[polId][k].filter((dc, dck) => {
      return dck != ck;
    });

    if (dcs[polId][k].length == 0) {
      dcs[polId][k].push({
        fileType: oldDoc.fileType,
        entity: oldDoc.entity,
      });
    }
    keepDocumentK = JSON.parse(JSON.stringify(dcs));
    toast.success("Document supprimé avec succès");
    setDocuments(dcs);
  }

  function uploadNewFile(polId, k, ck) {
    var dcs = JSON.parse(JSON.stringify(documents));
    dcs[polId][k] = [
      {
        fileType: dcs[polId][k][ck].fileType,
        entity: dcs[polId][k][ck].entity,
        isNewVersion: true,
      },
    ].concat(dcs[polId][k]);
    keepDocumentK = JSON.parse(JSON.stringify(dcs));

    setDocuments(dcs);
  }
  function cancelNewVersion(polId) {
    getDocuments(polId, true);
  }

  function getAmendments(policyId) {
    if (amendments[policyId] && amendments[policyId].length > 0) return false;
    setIsLoading(true);
    Axios.get(API_URL + "amendments/policy/" + policyId)
      .then((res) => {
        var amd = JSON.parse(JSON.stringify(amendments));
        amd[policyId] = res.data;
        setAmendments(amd);
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  function getQuittances(policyId) {
    if (receipts[policyId] && receipts[policyId].length > 0) return false;
    setIsLoading(true);
    Axios.get(API_URL + "receipts/policy/" + policyId)
      .then((res) => {
        var amd = JSON.parse(JSON.stringify(receipts));
        amd[policyId] = res.data;
        setReceipts(amd);
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    keepDocumentK = JSON.parse(JSON.stringify(documents));
  }, [documents]);

  return (
    <Accordion
      btnClassName="shadow-sm p-0"
      cardClassName="shadow-none bg-transparent mb-1"
      bodyClassName=""
      color="default"
      btnStyle={{
        border: "1px solid #d8d8d8",
        borderRadius: 8,
        overflowX: "hidden",
      }}
      borderColor="info"
      items={policies.map((pol, polk) => {
        return {
          ref: "policy-" + pol.id,
          title: (
            <div className="d-flex flex-row flex-wrap align-items-center justify-content-between w-100  user-title-container">
              <div className="d-flex flex-row flex-wrap align-items-center user-title-header">
                <div
                  className="d-flex flex-column text-white text-center bg-info p-2 mr-2 user-title-icon"
                  style={{
                    fontSize: 12,
                    lineHeight: "22px",
                    textTransform: "capitalize",
                    borderBottomRightRadius: 10,
                  }}
                >
                  <i className="fa fa-handshake mt-1" />
                  Contrat
                </div>

                <div className="text-left user-title-info">
                  {pol.reference} - <b>{pol.title}</b> - {pol.product?.name}
                  <div className="text-left">{pol.identification}</div>
                </div>
              </div>
            </div>
          ),
          onClick: () => {
            getAmendments(pol.id);
          },
          content: (
            <>
              {pol.isGozen && pol.lastAmendment?.canRenew && (
                <div className="d-flex justify-content-end mb-2">
                  <Link
                    className="btn btn-default btn-sm mr-1 d-flex align-items-center justify-content-center"
                    to={"/contracts/" + pol.id + "/renew"}
                    style={{ height: 25 }}
                  >
                    <i className="fa fa-sync mr-2" />
                    Renouveler ce contrat
                  </Link>
                </div>
              )}
              {pol.isGozen && !pol.lastAmendment?.canRenew && (
                <div className="d-flex justify-content-end mb-2">
                  <Link
                    className="btn btn-default btn-sm mr-1 d-flex align-items-center justify-content-center"
                    to={"/contracts/" + pol.id}
                    style={{ height: 25 }}
                  >
                    <i className="fa fa-edit mr-2" />
                    Modifier ce contrat
                  </Link>
                </div>
              )}
              <Tabs
                k={polk}
                items={[
                  {
                    id: "informations" + polk,
                    title: "Informations",
                    content: (
                      <div className="row mx-auto" style={{ maxWidth: 900 }}>
                        <div className="col-12 col-md-6">
                          <Control
                            label="Date de création"
                            className="readonly"
                            value={DateTime.fromISO(pol.createdAt).toFormat("dd/MM/yyyy")}
                          />
                          <Control
                            label="Date d'effet"
                            className="readonly"
                            value={
                              amendments[pol.id]
                                ? DateTime.fromISO(amendments[pol.id][0].effectDate).toFormat(
                                    "dd/MM/yyyy"
                                  )
                                : ""
                            }
                          />
                          <Control label="Référence" className="readonly" value={pol.reference} />
                          <Control
                            label="Compagnie d'assurance"
                            className="readonly"
                            value={pol.company.name}
                          />
                          <Control label="Type" className="readonly" value={pol.productType.name} />
                        </div>
                        <div className="col-12 col-md-6">
                          <Control
                            label="Date de modification"
                            className="readonly"
                            value={
                              pol.updatedAt
                                ? DateTime.fromISO(pol.updatedAt).toFormat("dd/MM/yyyy")
                                : "inconnue"
                            }
                          />
                          <Control
                            label="État"
                            className="readonly"
                            value={contractStatuses.find((f) => f.id == pol.status).name}
                          />
                          <Control
                            label="Produit"
                            className="readonly"
                            value={pol.product.name + " - " + pol.product.businessName}
                          />
                          <Control
                            label="Options"
                            type="btnList"
                            className="readonly"
                            itemClassName="shadow-none"
                            datas={
                              pol.options
                                ? pol.options.map((p, pk) => {
                                    return {
                                      id: pk,
                                      name: p,
                                    };
                                  })
                                : []
                            }
                          />
                        </div>
                      </div>
                    ),
                  },
                  {
                    dataPriv: "app_downloadcompanycontractdocs-" + clientId,
                    id: "documents" + polk,
                    title: "Documents",
                    onClick: () => {
                      getDocuments(pol.id);
                    },
                    content:
                      isLoadingDoc == pol.id ? (
                        <Loader withContainer />
                      ) : (
                        <div className="row">
                          {documents[pol.id] ? (
                            <DocumentsDisplay
                              inline={isMobile}
                              documents={documents[pol.id]}
                              documentUploaded={(k, ck, doc) =>
                                documentUploaded(pol.id, k, ck, doc)
                              }
                              documentRemoved={(k, ck, doc) => documentRemoved(pol.id, k, ck, doc)}
                              uploadNewFile={(k, ck) => uploadNewFile(pol.id, k, ck)}
                              authorizedModels={isBOA ? false : ["Client", "UserCustomer"]}
                              cancelNewVersion={() => cancelNewVersion(pol.id)}
                              productName={pol.productType.name}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ),
                  },
                  {
                    dataPriv: "app_viewpayments-" + clientId,
                    id: "amendments" + polk,
                    title: "Avenants",
                    content: amendments[pol.id] ? (
                      amendments[pol.id].map((amendment, amk) => (
                        <Accordion
                          className="mt-1 acc-am-1"
                          btnClassName={"shadow-sm  p-0 "}
                          btnStyle={{
                            borderRadius: 8,
                            border: "1px solid #e6e6e6",
                            overflowX: "hidden",
                          }}
                          borderColor="info"
                          btnContainerClassName={""}
                          cardClassName="shadow-none bg-transparent"
                          color={"default"}
                          bodyClassName="pl-4 pr-0  py-0"
                          k={"am" + polk + "" + amk}
                          items={[
                            {
                              title: (
                                <AccordionItemTitle title="Avenant" icon="balance-scale">
                                  <div className="text-left  user-title-info">
                                    <div className="client-name">
                                      {"Créé le : " +
                                        DateTime.fromISO(amendment.createdAt).toFormat(
                                          "dd/MM/yyyy"
                                        ) +
                                        " Date d'effet : " +
                                        DateTime.fromISO(amendment.effectDate).toFormat(
                                          "dd/MM/yyyy"
                                        )}
                                    </div>
                                  </div>
                                </AccordionItemTitle>
                              ),
                              content: <AmendmentItem amendment={amendment} />,
                            },
                          ]}
                        />
                      ))
                    ) : (
                      <span className="text-muted">Les avenants s'afficheront ici</span>
                    ),
                  },
                  {
                    dataPriv: "app_viewpayments-" + clientId,
                    id: "receipts" + polk,
                    title: "Quittances",
                    onClick: () => {
                      getQuittances(pol.id);
                    },
                    content: receipts[pol.id] ? (
                      receipts[pol.id].map((receipt, amk) => (
                        <Accordion
                          className="mt-1 acc-am-1"
                          btnClassName={"shadow-sm  p-0 "}
                          btnStyle={{
                            borderRadius: 8,
                            border: "1px solid #e6e6e6",
                            overflowX: "hidden",
                          }}
                          borderColor="info"
                          btnContainerClassName={""}
                          cardClassName="shadow-none bg-transparent"
                          color={"default"}
                          bodyClassName="pl-4 pr-0  py-0"
                          k={"rec" + polk + "" + amk}
                          items={[
                            {
                              title: (
                                <AccordionItemTitle title="Quittance" icon="euro-sign">
                                  <div className="text-left  user-title-info">
                                    <div className="client-name">
                                      <span>
                                        {"Créé le : " +
                                          DateTime.fromISO(receipt.createdAt).toFormat(
                                            "dd/MM/yyyy"
                                          )}
                                      </span>
                                      <span className="ml-2">
                                        Montant : <strong>{receipt.premiumWithVat + "€"}</strong>
                                      </span>
                                      <span className="ml-2">
                                        Période :{" "}
                                        <strong>
                                          {DateTime.fromISO(receipt.effectDate).toFormat(
                                            "dd/MM/yyyy"
                                          )}{" "}
                                          -{" "}
                                          {DateTime.fromISO(receipt.endDate).toFormat("dd/MM/yyyy")}
                                        </strong>
                                      </span>
                                      <div>
                                        <span className="ml-2">
                                          Date limite :{" "}
                                          <strong>
                                            {DateTime.fromISO(receipt.limitDate).toFormat(
                                              "dd/MM/yyyy"
                                            )}
                                          </strong>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </AccordionItemTitle>
                              ),
                              content: <ReceiptItem receipt={receipt} />,
                            },
                          ]}
                        />
                      ))
                    ) : (
                      <span className="text-muted">Les quittances s'afficheront ici</span>
                    ),
                  },
                  {
                    id: "events" + polk,
                    title: "Événements",
                    content: (
                      <div className="row">
                        <div className="col-12">
                          <TimelineFiltered
                            className="mt-4"
                            showFilters={true}
                            initialSearch={{
                              model: "policies",
                              id: pol.id,
                              searchString: "",
                              beginAt: DateTime.local().minus({ months: 1 }).startOf("day"),
                              endAt: DateTime.local().endOf("day"),
                              type: "",
                            }}
                          />
                        </div>
                      </div>
                    ),
                  },
                ]}
              />
            </>
          ),
        };
      })}
    />
  );
};

export default TabPolicies;
