import { useState } from "react";
import dataService from "../../../helpers/dataService";

const useMesFactures = () => {
  const [isLoadingFactures, setIsLoadingFactures] = useState(false);
  const [factures, setFactures] = useState(null);

  const getFactures = () => {
    setIsLoadingFactures(true);
    dataService.get(
      `billings/mines`,
      setFactures,
      (err) => {},
      () => setIsLoadingFactures(false)
    );
  };

  return {
    isLoadingFactures,
    factures,
    getFactures,
  };
};

export default useMesFactures;
