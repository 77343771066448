import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Axios from "axios";
import { API_URL } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import Avatar from "../../common/Avatar";
import PasswordControl from "../../common/PasswordControl";
import ImageFileCrop2 from "../../common/ImageFileCrop/ImageFileCrop2";
import GreyCard from "../Dashboard/GreyCard";
import MyRoles from "./MyRoles";
import dataService from "../../../helpers/dataService";

const UserDetail = (props) => {
  const [state, dispatch] = useStore();

  var params = props.location.search;

  useEffect(() => {
    if (params == "?avatar") {
      setShowPassword(false);
      setModifyAvatar(true);
    }
    if (params == "?password") {
      setModifyAvatar(false);

      setShowPassword(true);
    }
  }, [props.location.search]);

  const [user, setUser] = useState({
    comment: "",
    company: "",
    firstname: "",
    lastname: "",
    createdAt: "",
    function: "",
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingPassword, setIsSavingPassword] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [modifyAvatar, setModifyAvatar] = useState(params == "?avatar" ? true : false);
  const [showPassword, setShowPassword] = useState(params == "?password" ? true : false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [modifyEmail, setModifyEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [emailError, setEmailError] = useState({});

  useEffect(() => {
    setIsLoading(true);
    Axios.get(API_URL + "customers/me")
      .then((res) => {
        setUser(res.data);
        setEmail(res.data.email);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error(
            "Une erreur est suvenue lors de la récupération des informations de votre compte"
          );
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  function updateData(e) {
    var { name, value, type, checked } = e.target;
    if (type == "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    }
    setIsSaved(false);
    setUser({ ...user, [name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});

    Axios.patch(API_URL + "customers/me", user)
      .then((res) => {
        setUser(res.data);
        setIsSaved(true);
        toast.success("Vos informations ont bien été modifiées");
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Une erreur est survenue lors de la modification de vos informations");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsSaving(false);
      });
  }
  var pwdIdentical = password === passwordConfirm && (password + "").length;

  function savePassword() {
    if (!pwdIdentical) {
      toast.error("Les mots de passe ne sont pas identiques");
    } else {
      setIsSavingPassword(true);
      Axios.patch(API_URL + "customers/me/password", { password: password })
        .then((res) => {
          toast.success("Votre mot de passe a bien été modifié.");
        })
        .catch((err) => {
          toast.error("Une erreur est survenue");
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSavingPassword(false);
        });
    }
  }

  function saveEmail() {
    if (email == user.email) {
      setEmailError({ email: "Cet email est identique au précédent" });
      return false;
    }
    if (!window.confirm("Êtes vous certain(e) de vouloir modifier votre email ?")) return false;
    setIsLoadingEmail(true);
    dataService.patch(
      `customers/me/email`,
      { email },
      (datas) => {
        setModifyEmail(false);
        setUser({ ...user, email });
        toast.success(
          "Votre email a bien été modifié, utilisez le lors de vos prochaines connexions."
        );
      },
      setEmailError,
      () => setIsLoadingEmail(false)
    );
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader withContainer={true} />
  ) : (
    <Page margin="mt-5" errors={errors}>
      {modifyAvatar ? (
        <>
          <button
            className="btn btn-link pl-0 text-success mb-2"
            onClick={() => setModifyAvatar(false)}
          >
            <i className="fa fa-arrow-left mr-2" />
            Retour
          </button>
          <p className="custom-p ">
            <strong>Choisissez une photo</strong> parmi vos fichiers ou prenez directement un selfie
            si vous êtes sur mobile.
          </p>
        </>
      ) : showPassword ? (
        <>
          <button
            className="btn btn-link pl-0 text-success mb-2"
            onClick={() => setShowPassword(false)}
          >
            <i className="fa fa-arrow-left mr-2" />
            Retour
          </button>
          <p className="custom-p ">
            <strong>Modifiez votre mot de passe</strong>
            <br />
            régulièrement pour augmenter la sécurité de votre compte.
          </p>
        </>
      ) : (
        <p className="custom-p mb-5">
          <strong>Vos informations de profil</strong> peuvent être mises à jour ici.
        </p>
      )}

      <GreyCard>
        {modifyAvatar ? (
          <ImageFileCrop2 setModifyAvatar={setModifyAvatar} />
        ) : !showPassword ? (
          <div
            className="d-flex flex-column align-items-center justify-content-center mb-3 position-relative cursor-pointer"
            onClick={() => setModifyAvatar(true)}
          >
            <Avatar width={150} height={150} id={user.id} acronymSize={150} acronymFontsize={30} />
            <button style={{}} className="btn btn-link btn-sm mt-2 ">
              Modifier ma photo
            </button>
          </div>
        ) : null}
        {!modifyAvatar && (
          <>
            {!modifyEmail && !showPassword && (
              <div className="d-center">
                <button
                  className="btn btn-link  mx-auto"
                  onClick={() => setShowPassword((p) => !p)}
                >
                  Changer mon mot de passe
                </button>
              </div>
            )}
            {!showPassword && (
              <div className="d-center mb-4 flex-column">
                {!modifyEmail ? (
                  <button
                    className="btn d-center"
                    style={{ color: "#5F5F5F" }}
                    onClick={() => setModifyEmail(true)}
                  >
                    <div
                      style={{
                        maxWidth: 300,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        fontSize: 17,
                        lineHeight: "20px",
                        fontWeight: "400",
                      }}
                    >
                      {user.email}
                    </div>
                    <i className="fa fa-edit ml-2" />
                  </button>
                ) : (
                  <>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        saveEmail();
                      }}
                      className="w-100 d-center align-items-start"
                      style={{ color: "#5F5F5F" }}
                    >
                      <Control
                        containerStyle={{ width: "85%" }}
                        margin="mb-2"
                        name="email"
                        value={email}
                        change={(e) => setEmail(e.target.value)}
                        error={emailError}
                      />
                      <button
                        className="btn btn-success"
                        style={{
                          width: "15%",
                          height: 40,
                        }}
                        type="submit"
                      >
                        {isLoadingEmail ? (
                          <Loader color={"#ffffff"} size={24} />
                        ) : (
                          <i className="fa fa-check" />
                        )}
                      </button>
                    </form>
                    <div className="w-100">
                      <button className="btn btn-link" onClick={() => setModifyEmail(false)}>
                        <i className="fa fa-arrow-left mr-2" />
                        Annuler
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            {!showPassword && !modifyEmail && (
              <>
                <Control
                  name="gender"
                  type="btnList"
                  datas={state.constants.items.GENDERS}
                  btnInline={true}
                  value={user.gender}
                  change={updateData}
                  error={errors}
                />
                <Control
                  label="Nom"
                  name="lastname"
                  value={user.lastname}
                  change={updateData}
                  error={errors}
                />
                <Control
                  label="Prénom"
                  name="firstname"
                  value={user.firstname}
                  change={updateData}
                  error={errors}
                />
                <Control
                  label="Téléphone"
                  name="phone"
                  type="tel"
                  value={user.phone}
                  change={updateData}
                  error={errors}
                />
                <div className="d-center w-100">
                  <SaveBtn
                    className="mt-4 btn-sm"
                    type="primary"
                    save={save}
                    text="Enregistrer"
                    isSaving={isSaving}
                    isSaved={isSaved}
                  />
                </div>
              </>
            )}

            {showPassword && (
              <div>
                <Control
                  label="Nouveau mot de passe"
                  name="password"
                  type="password"
                  autocomplete={"off"}
                  value={password}
                  change={(e) => setPassword(e.target.value)}
                  error={errors}
                />
                <PasswordControl
                  style={{ marginLeft: 7 }}
                  password={password}
                  minLength={6}
                  minUppercase={1}
                  minLowercase={3}
                  minNumber={1}
                  setCanSave={setPasswordValid}
                />
                <Control
                  label="Retapez le mot de passe"
                  name="passwordConfirm"
                  type="password"
                  value={passwordConfirm}
                  change={(e) => setPasswordConfirm(e.target.value)}
                  error={errors}
                />
                <span
                  className={`
                    d-flex align-items-center ml-2 
                    ${pwdIdentical ? "text-success" : "text-danger"}`}
                >
                  {<i className={"mr-2 fa fa-" + (pwdIdentical ? "check" : "times")} />}
                  Doit-être identique à celui du dessus{" "}
                </span>
                {passwordValid && pwdIdentical && (
                  <div className="d-center w-100">
                    <SaveBtn
                      type="primary"
                      className="mt-4 btn-sm"
                      save={savePassword}
                      isSaving={isSavingPassword}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {!modifyEmail && !modifyAvatar && !showPassword && <MyRoles />}
      </GreyCard>
    </Page>
  );
};

export default UserDetail;
